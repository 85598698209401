@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

body {
  font-family: 'rocGrotesk-regular', sans-serif;
  color: #142837
}

@font-face {
  font-family: "rocGrotesk-light";
  src: url("./assets/fonts/rocGrotesk/RocGrotesk-Light.otf");
  font-display: swap;
}

@font-face {
  font-family: "rocGrotesk-regular";
  src: url("./assets/fonts/rocGrotesk/RocGrotesk-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "rocGrotesk-medium";
  src: url("./assets/fonts/rocGrotesk/RocGrotesk-Medium.otf");
  font-display: swap;
}

@font-face {
  font-family: "rocGrotesk-bold";
  src: url("./assets/fonts/rocGrotesk/RocGrotesk-Bold.otf");
  font-display: swap;
}

input[type='password'] {
  font-family: Verdana !important;
  font-size: 14px;
  letter-spacing: 0.12em;
}

::-webkit-scrollbar {
  display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    height: fit-content;
    margin-bottom: -4px;
    font-family: 'rocGrotesk-regular', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 15px;
  transition: 0.2s ease all;
}


.textAreafloating-label {
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 10px;
  transition: 0.2s ease all;
}

.textAreaInput:focus ~ .textAreafloating-label,
/* .textAreaInput:not([value=""]) ~ .textAreafloating-label, */
.textAreaInput:valid ~ .textAreafloating-label,
.textAreaInput:focus:required ~ .textAreafloating-label,
.textAreaInput:focus:optional ~ .textAreafloating-label,
.textAreaInput:-webkit-autofill ~ .textAreafloating-label,
.textAreaInput:-webkit-autofill:hover ~ .textAreafloating-label, 
.textAreaInput:-webkit-autofill:focus ~ .textAreafloating-label, 
.textAreaInput:-webkit-autofill:active ~ .textAreafloating-label{
  top: 3px;
  bottom: 10px;
  left: 12px;
  font-size: 8px;
  opacity: 1;
}

input[type=date] ~ .floating-label,
.inputText:focus ~ .floating-label,
.inputText:not([value=""]) ~ .floating-label,
.inputText:focus:required ~ .floating-label,
.inputText:focus:optional ~ .floating-label,
.inputText:-webkit-autofill ~ .floating-label,
.inputText:-webkit-autofill:hover ~ .floating-label, 
.inputText:-webkit-autofill:focus ~ .floating-label, 
.inputText:-webkit-autofill:active ~ .floating-label{
  top: 3px;
  bottom: 10px;
  left: 14px;
  font-size: 8px;
  opacity: 1;
}

input[type=date] ~ .floating-label .required,
.inputText:focus ~ .floating-label .required,
.inputText:not([value=""]) ~ .floating-label .required,
.inputText:focus:required ~ .floating-label,
.inputText:focus:optional ~ .floating-label,
.inputText:-webkit-autofill ~ .floating-label .required,
.inputText:-webkit-autofill:hover ~ .floating-label .required, 
.inputText:-webkit-autofill:focus ~ .floating-label .required, 
.inputText:-webkit-autofill:active ~ .floating-label .required {
  font-size: 8px;
}

.floating-label.nav-search {
  position: absolute;
  pointer-events: none;
  left: 44px;
  top: 8px;
  transition: 0.2s ease all;
}

.inputText:focus ~ .floating-label.nav-search,
.inputText:not([value=""]) ~ .floating-label.nav-search,
.inputText:focus:required ~ .floating-label.nav-search,
.inputText:focus:optional ~ .floating-label.nav-search,
.inputText:-webkit-autofill ~ .floating-label.nav-search,
.inputText:-webkit-autofill:hover ~ .floating-label.nav-search, 
.inputText:-webkit-autofill:focus ~ .floating-label.nav-search, 
.inputText:-webkit-autofill:active ~ .floating-label.nav-search {
  top: -2px;
  bottom: 10px;
  left: 44px;
  font-size: 8px;
  opacity: 1;
}

.inputText:focus ~ .floating-label.nav-search .required,
.inputText:not([value=""]) ~ .floating-label.nav-search .required,
.inputText:focus:required ~ .floating-label.nav-search,
.inputText:focus:optional ~ .floating-labe.nav-searchl,
.inputText:-webkit-autofill ~ .floating-label.nav-search .required,
.inputText:-webkit-autofill:hover ~ .floating-label.nav-search .required, 
.inputText:-webkit-autofill:focus ~ .floating-label.nav-search .required, 
.inputText:-webkit-autofill:active ~ .floating-label.nav-search .required {
  font-size: 8px;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #DFE1E6;
  border-radius: 2px;
}

.skeleton::after {
  position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 1.5s infinite;
    content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.olLayerGooglePoweredBy{display:none}
.gmnoprint a, .gmnoprint span {
    display:none;
}
.gmnoprint div {
    background:none !important;
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}

:root {
    --gm-button-background-color:white;
    
}
@media (prefers-color-scheme: dark) {
    :root {
        --gm-button-background-color:white;
        
    }
}

.gm-control-active {
    background-color:var(--gm-button-background-color) !important;
    border-radius: 5px !important;
    margin-bottom: 5px !important;
    color: #5452BF !important;
    height: 34px !important;
    width: 34px !important;
}
.gm-control-active>img {
    height: 10px !important;
    width: 10px !important;
    color: #5452BF !important;
}
.gmnoprint>div{
  box-shadow: none !important;
}
.gm-style{
  border-radius: 5px !important;
}
.map-design>div>div>div{
  border-radius: 5px !important;
}

/* MentionField.css */

/* Remove border and outline */
.mention-field {
    border: none;
    outline: none;
}

/* Remove box-shadow on focus */
.mention-field:focus {
    box-shadow: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #14283714; */
  background-color: #E2E8F0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggleInput:checked + .slider {
  background-color: #12A589;
}

.toggleInput:focus + .slider {
  box-shadow: 0 0 1px #12A589;
}

.toggleInput:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.tox .tox-statusbar__path{
  display: none !important;
}